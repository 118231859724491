export const en = {
  header: {
    首頁: "Home",
    行情戰績: "Our Achievements",
    推廣優惠: "Promotions",
    夥伴招募: "Join Our Team",
  },
  banner: {
    swiper1: "/banner-us.png",
    swiper2: "",
  },
  home: {
    抄作業: "Follow the experts",
    跟著專業操盤手實戰: "Trade with their strategies to earn big profits",
    超額利潤: "earn big profits",
    每日BTC: "Daily market insights — BTC & ETH",
    當日熱門幣種行情解讀: "Analysis of popular cryptocurrencies on the day (SAND, SOL, FTM, MATIC, and SHIB)",
    高勝率即時交易策略: "Instant, high-return trading strategies",
    風險提醒: "Risk alerts and important news updates",
    新手交易教室: "Trading Classroom",
    他們盈利過億: "Learn every aspect of crypto trading from the experienced traders",
    認清自己參與的角色: "Recognise your strength <br/>and formulate strategies to earn profits ",
    快速搞定: "Get ready to invest <br/>in 10 minutes",
    免費領取: "Receive 10,000 USDT <br/>for free to start demo trading",
    防騙指南: "Fraud Prevention Guide <br/>to help you protect your assets",
    新幣研究院: "Crypto Research Centre<br/><span>Stay at the forefront of the crypto market for more opportunities</span>",
    怪獸領隊: "Our Monster Leaders",
    Godzilla: "Godzilla",
    精準猜測點位: "A day trader who specialises in major coins. He has 15 years of trading experience and has been trading BTC and ETH for 4 years. Having seen multiple market cycles, Godzilla is exceptionally proficient at predicting price points. He managed to make 60 profitable trades out of 60 transactions. His profit rate is above 80%.",
    Hydra: "Hydra",
    加密貨幣資深玩家: "A veteran crypto trader who has a preference for non-mainstream coins, also a financial product designer and an engineer. Skilled at trading coins with strong performance such as SOL, MATIC, SUSHI, PIG, SHIB, and SOS. Hydra is also an early DeFi user. ",
    Ork: "Ork",
    熱門加密貨幣趨勢交易員: "A well-known crypto trader who focuses on the profit/loss ratio and always enters the market at the best price. Ork has accumulated 2,000 paid members in a year and they have all achieved great returns.",
    Ladon: "Ladon",
    比特幣早期玩家: "An early Bitcoin trader who releases daily video updates on the Bitcoin market. Original and intriguing, Ladon's analysis offer detailed insights based on price patterns and technical indicators. Ladon focuses on critical analysis of the market, which shows from the commentary he makes.",
    如果你在: "If you have unique insights on the crypto market, you are more than welcome to join our team.<a href='https://discord.gg/5kTYgctgd9'> Please click to find out more details</a>. ",
    加入我們: "Join Us",
    會員費: "Membership Fee:<span>0.1</span>ETH/Month<span>1ETH/</span>Year",
    驗證合作交易所: "Users from verified cryptocurrency exchanges can get Cryptomonster membership for free!",
    如過你是以下交易所: "If you are a user of the following exchanges: BTCC, Binance, Upbit, Bybit, or Max, you can get a Crytomonster membership for free. Please send your account registration number to our group administrator to apply for the free membership.",
    付費會員請咨詢: "Paid Membership Inquiry:",
    免費開放: "Free of charge:",
    風險聲明: 'Risk warning: Although the content provided by Cryptomonster represents the views of Cryptomonster, it is for reference only and does not constitute investment advice. The Cryptomonster Team does not assume any responsibility for the direct or indirect losses caused by investors’ transactions. Margin trading in the cryptocurrency market is an activity associated with high risks. While it may bring huge gains, it may also cause huge losses. All trading strategies are executed at your own discretion and risk. Past earnings do not predict future performance. Please trade at your own risk.',
  },
  achievements: {
    行情戰績: "Our Achievements",
    "2022年": "2022",
    "1月": "January",
    "2021年": "2021",
    "12月": "December",
    "11月": "November",
    "第三季度":"Q3",
    "第二季度":"Q2",
    "第一季度":"Q1",
  },
  offers: {
    推广优惠: "Promotional Offers",
    CFD合约首选: "Your first choice in CFD trading",
    独家加赠200u: "Get exclusive bonus of 200USDT",
    BTCC是全球: "11-year-old brand, the first choice for BTC/ETH contract trading, with the lowest transaction cost",
    推薦原因: "Pros:",
    交易體驗佳: "Instant order execution",
    最佳深度: "Top market liquidity, no slippage, no funding rates, low transaction fees",
    歷史悠久的平臺: "An established platform with high levels of security",
    提供模擬交易: "Beginner-friendly with its demo trading mode",
    劣勢: "Cons:",
    僅提供市值前20的主流幣種的合約交易: "It only provides contract trading of the top 20 mainstream currencies by market value. Altcoins are not supported.",
    立即下載: "Download Now",
    下載:"https://trade.btcc.com/en/register?agentCode=21O1RB&utm_source=kol",
    Binance注冊:"https://accounts.binance.com/en-US/register?ref=PPMUM32W",
    Gate注冊:"https://www.gate.ac/signup/56341",
    Max: "Binance",
    幣種最全:"with most complete currency, first choice for spot trading",
    台灣現貨平臺: "A spot trading platform in Taiwan",
    max优势: "A licenced platform in Taiwan that supports TWD deposits and withdrawals",
    注冊: "Register",
    Coinlist: "Gate",
    小幣合約交易首選:"The first choice for Altcoins currency contract transactions, and you can also participate in the subscription of new currency activities"
    
  },
  partner:{
    夥伴招募: "Join Our Team",
    加密怪獸致力於: "Cryptomonster is committed to providing professional and objective market analysis and industry research to help investors enhance their trading skills and profitability. Most of our members are researchers, analysts, media specialists, project specialists, product managers, developers, and professional investors who have been in the industry for years. In 2021, they created Cryptomonster in the form of DAO. Currently, Cryptomonster has expanded to China, Taiwan, South Korea, Australia, and many other markets.",
    内容提供者: "Content Provider",
    如果你對加密貨幣新產業: "You have unique insights about the new cryptocurrency industry or you are an experienced trader.",
    首席陪聊官: "Community Manager",
    如果你有很長的時間在社交媒體上: "You have experience in social media and a strong passion for cryptocurrency, and you like to share your ideas and interact with others. ",
    外交部長: "Social Trader",
    如果你活躍在加密貨幣行業: "You are active in the cryptocurrency industry and know a lot of people in the circle. You are looking for more ideas and opinions exchange.",
    社群版主: "Crypto Influencer",
    如果你是加密貨幣行業的版主: "You are a key opinion leader in the industry and you have a social media channel to share your views with others.",
    推廣流量: "Promote traffic",
    優質内容: "Quality content",
    資金支持: "Fund our business",
    收益分紅: "Exciting profits",
    福利優惠: "Perks and benefits",
    專屬NFT頭像: "Exclusive NFT avatar",
    只要你擁有其中一項: "If you are ready to provide one of the above to us and are interested in these rewards, ",
    點擊聯係加入我們:"please don’t hesitate and join us now.",
    聯係:"Join Us",
  }
};
