export  const tc = {
    header:{
        首頁:"首頁",
        行情戰績:"行情戰績",
        推廣優惠:"推廣優惠",
        夥伴招募:"夥伴招募",
   },
   banner:{
    swiper1:"/banner-cn.png",
    swiper2:"",
  },
   home:{
     抄作業:"抄作業",
     跟著專業操盤手實戰:"跟著專業操盤手實戰，獲取" ,
     超額利潤:"超額利潤",
     每日BTC:"每日BTC,ETH行情解讀",
     當日熱門幣種行情解讀:"當日熱門幣種行情解讀（sand,sol，FTM,matic，shib)",
     高勝率即時交易策略:"高勝率即時交易策略",
     風險提醒:"風險提醒，重大行情避坑",
     新手交易教室:"新手交易教室",
     他們盈利過億:"他們盈利過億，願意手把手教你交易秘籍。",
     認清自己參與的角色:"認清自己參與的角色<br/>賺對應的錢。 ",
     快速搞定:"10分鐘快速搞定<br/>投資前準備",
     免費領取:"免費領取10000美金<br/>開始模擬交易",
     防騙指南:"防騙指南<br/>別讓騙子覺得是你蠢。",
     新幣研究院:"新幣研究院<br/><span>新幣種挖掘，掌握財富先機</span>",
     怪獸領隊:"怪獸領隊",
     Godzilla:"Godzilla",
     精準猜測點位:"精準猜測點位。一招斐波那契創造一月60單，60單全勝記錄。勝率始終保持在80%+。",
     Hydra:"Hydra",
     加密貨幣資深玩家:"加密貨幣資深玩家，非主流數字貨幣交易員，金融產品設計師和工程師。擅長挖掘強勢幣種.10美金挖掘SOL，1美金介入MATIC；DEFI早期玩家，sushi百倍回報；快速捕捉MEME代幣的啟動，pig20倍回報，SHIB500倍回報，sos30倍回報。",
     Ork:"Ork",
     熱門加密貨幣趨勢交易員:"熱門加密貨幣趨勢交易員。講究盈虧比，出手必是最佳進場位置。一年内服務付費會員2000，全部實現翻倉回報。",
     Ladon:"Ladon",
     比特幣早期玩家:"比特幣早期玩家，比特幣行情分析視頻日更版主，基於客觀的技術型態、技術指標，全部影片原創，強調獨立思考;",
     如果你在:"（如果你在加密貨幣領域有自己獨立的觀點，歡迎入駐怪獸戰隊,<a href='https://discord.gg/5kTYgctgd9'>詳情點擊</a>）",
     加入我們:"加入我們",
     會員費:"會員費：<span>0.1</span>ETH/月，<span>1ETH/</span>年。",
     驗證合作交易所:"驗證合作交易所免費可免費試用。",
     如過你是以下交易所:"如過你是以下交易所注冊用戶：BTCC，biannce，upbit，bybit,max發送注冊號給版主即可驗證，免費享受會員服務。",
     付費會員請咨詢:"付費會員請咨詢：",
     免費開放:"免費開放：",
     風險聲明:'風險聲明：加密怪獸團隊所提供内容雖代表"加密怪獸團隊"觀點，但皆爲參考且不構成投資建議，對投資者交易形成的直接間接損失，加密怪獸團隊不承擔任何責任。加密貨幣市場有很大的潛在回報，但也有很大的潛在風險。您必須意識到風險並願意接受它們才能在金融市場進行交易。任何交易系統或方法的過去表現並不一定代表未來結果，請自行決策。',
    },
    achievements:{
        行情戰績:"行情戰績",
        "2022年": "2022年",
        "1月": "1月",
        "2021年": "2021年",
        "12月": "12月",
        "11月": "11月",
        "第三季度":"第三季度",
        "第二季度":"第二季度",
        "第一季度":"第一季度",
    },
    offers:{
        推广优惠:"推广优惠",
        CFD合约首选:"BTCC合約交易所",
        独家加赠200u:"独家加赠200u",
        BTCC是全球:"11年老牌，BTC/ETH合約交易首選，交易成本最低",
        推薦原因:"推薦原因",
        交易體驗佳:"註冊享手續費22%折扣優惠",
        最佳深度:"最佳深度，無滑點，無資金費率，交易成本低。",
        歷史悠久的平臺:"歷史悠久的平臺，安全正規。",
        提供模擬交易:"提供模擬交易，新手友好",
        劣勢:"劣勢：",
        僅提供市值前20的主流幣種的合約交易:"僅提供市值前20的主流幣種的合約交易。不支持山寨幣交易。",
        立即下載:"立即下載",
        下載:"https://trade.btcc.com/zh-TW/register?agentCode=21O1RB&utm_source=kol",
        Binance注冊:"https://accounts.binance.com/zh-CN/register?ref=PPMUM32W",
        Gate注冊:"https://www.gate.ac/signup/56341",
        Max:"Binance",
        幣種最全:"幣種最全，現貨首選",
        台灣現貨平臺:"台灣現貨平臺",
        支持台币出入金:"支持台币出入金",
        注冊:"注冊",
        現貨手續費減免:"現貨手續費減免20%，合約手續費減免10%",
        Coinlist:"Gate",
        // 打新首选平台:"打新首选平台",
        小幣合約交易首選:"小幣合約交易首選，還可參與打新"

    },
    partner:{
        夥伴招募:"夥伴招募",
        加密怪獸致力於:"加密怪獸致力於提供專業客觀的行情分析，行業研究，幫助普通投資人提高在加密貨幣市場的盈利水平。團隊成員多是從事加密貨幣行業的研究員分析師，媒體，項目方，產品經理，開發者，資深投資人。他們穿越牛熊，于2021年以DAO的形式，创立”加密怪兽”。目前已經拓展中國，中國台灣，韓國，澳大利亞等多個市場。",
        内容提供者:"内容提供者",
        如果你對加密貨幣新產業: "如果你對加密貨幣新產業有自己督導的見解，或者你是加密貨幣交易的高級玩家，歡迎加入。",
        首席陪聊官: "首席陪聊官",
        如果你有很長的時間在社交媒體上: "如果你有很長的時間在社交媒體上，對加密貨幣行業有很高的熱情，願意溝通聊天，歡迎加入。 ",
        外交部長: "外交部長",
        如果你活躍在加密貨幣行業: "如果你活躍在加密貨幣行業，認識很多的人，想實現更多的資源交換，歡迎加入。",
        社群版主: "社群版主",
        如果你是加密貨幣行業的版主: "如果你是加密貨幣行業的版主，意見領袖，歡迎加入。這是一個有趣的圈子，一個優秀的聯盟。",
        推廣流量: "推廣流量",
        優質内容: "優質内容",
        資金支持: "資金支持",
        收益分紅: "收益分紅",
        福利優惠: "福利優惠",
        專屬NFT頭像: "專屬NFT頭像",
        只要你擁有其中一項:"只要你擁有其中一項，想獲得其他項，",
        點擊聯係加入我們:"點擊聯係加入我們",
        聯係:"聯係",
    }
    
}