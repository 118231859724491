
export   const kr = {
    header:{
        首頁:"홈",
        行情戰績:"성과 ",
        推廣優惠:"이벤트",
        夥伴招募:"파트너 모집",
   },
   banner:{
    swiper1:"/banner-ko.png",
    swiper2:"",
  },
  home:{
    抄作業:"전문가 팔로우",
    跟著專業操盤手實戰:"전문가들의 전략으로 거래하고 " ,
    超額利潤:"큰 수익을 얻어보세요",
    每日BTC:"매일 BTC, ETH 시세 분석",
    當日熱門幣種行情解讀:"당일 인기 암호화폐 분석(SAND, SOL, FTM, MATIC, SHIB)",
    高勝率即時交易策略:"고승률 거래 전략",
    風險提醒:"위험 경고 및 주요 업계 뉴스 업데이트",
    新手交易教室:"초보자 거래 교실",
    他們盈利過億:"프로 트레이더로부터 암호화폐 거래에관한 모든 것을 배워보세요.",
    認清自己參與的角色:"자신의 상태를 확인하고 <br/>수익을 올릴 수 있는 전략을 세워보세요.",
    快速搞定:"단 10분으로<br/> 투자준비를 해보세요",
    免費領取:"$10,000달러를 무료로 수령하고 <br/>모의거래를 시작하세요",
    防騙指南:"자산을 보호하는 데 <br/>도움이 되는 사기 방지 가이드",
    新幣研究院:"암호화폐 연구소<br/><span> 암호화폐 시장의 선두에서 더 많은 수익의 기회를 잡아보세요,</span>",
    怪獸領隊:"몬스터 리더 리스트",
    Godzilla:"고질라  Godzilla",
    精準猜測點位:"메이저 암호화폐 전문 데일리 트레이더. 고질라는 15년의 거래 경험이 있으며 4년 동안 집중적으로 비트코인(BTC)과 이더리움(ETH)에 중점적으로 거래했습니다. 여러 시장 주기를 보아온 고질라는 가격대를 예측하는 데 매우 능숙합니다. 피보나치의 기술분석으로 한 달에 무려 60개 주문의 우승 기록을 만들었습니다. 승률은 항상 80% 이상입니다.",
    Hydra:"히드라  Hydra",
    加密貨幣資深玩家:"알트 코인 거래를 선호하는 베테랑 트레이너 이자 금융 상품 디자이너 이기도 합니다.SOL, MATIC, SUSHI, PIG, SHIB, SOS 등 강력한 상승 잠재력이 있는 알트코인 거래에 능합니다.  히드라는 디파이(DeFi)  초기 이용자이기도 합니다.",
    Ork:"오크  Ork",
    熱門加密貨幣趨勢交易員:"인기 암호화폐 트렌드 트레이더로 손익 비율에 중점을 둡니다.오크는 1년 동안 2,000명에 유료 회원에게 서비스를 제공하였고 회원들에게 큰 수익을 안겨주었습니다.",
    Ladon:"라돈  Ladon ",
    比特幣早期玩家:"라돈은 비트코인 시장 분석 비디오를 매일 업데이트하는 초보 암호화폐 ​​트레이더입니다. 독창적이고 흥미로운 라돈의 분석은 시세 패턴 및 기술 지표를 기반으로 하며 자세한 시장 분석과 비판에 중점을 둡니다.;",
    如果你在:"암호화폐 시장에 대해 특별한 통찰력이 있다면 크립토몬스터 팀에 합류하는 것을 환영합니다. <a href='https://discord.gg/5kTYgctgd9'> 자세한 내용을 보시려면 클릭하세요</a>.",
    加入我們:"가입하기：",
    會員費:"회원비：<span>0.1</span>ETH/월，<span>1ETH/</span>년。",
    驗證合作交易所:"검증된 제휴 거래소의 회원은 무료로 크립토몬스터의 회원이 될 수 있습니다!",
    如過你是以下交易所:"BTCC, Binance, Upbit, Bybit, Max 거래소의 회원이라면 무료로 크립토몬스터의 회원이 되실 수 있습니다.  그룹 관리자에게 계정 등록 번호를 보내고 무료 회원 서비스를 즐겨보세요.",
    付費會員請咨詢:"유효회원은 문의：",
    免費開放:"무료 채널：",
    風險聲明:'위험 경고:크립토 몬스터 팀이 제공하는 내용은 “크립토 몬스터 팀"의 견해를 대변하지만,  투자자의 거래 형성에 대한 직접 또는 간접적인 손실에 대해은 크립토 몬스터 팀은 어떠한 책임도 지지 않습니다. 암호화폐 시장은 잠재적인 보상이 크지만 잠재적인 위험도 크기 때문에 리스크를 인지하고 이를 수용해야 금융시장에서 거래가 가능합니다 . 과거의 수입이 반드시 미래의 수익의 결과를 가져오는 것은 것은 아닙니다. 모든 거래 전략은 귀하의 선택에 달려있으므로  스스로 신중하게 결정하시길 바랍니다.',
   },
   achievements:{
       行情戰績:"성과 기록：",
       "2022年": "2022년",
       "1月": "1월",
       "2021年": "2021년",
       "12月": "12월",
       "11月": "11월",
       "第三季度":"제3분기",
       "第二季度":"제2분기",
       "第一季度":"제1분기",
   },
   offers:{
       推广优惠:"추천혜택",
       CFD合约首选:"첫번째 추천 선물 거래소",
       独家加赠200u:"200USDT 보너스 받기",
       BTCC是全球:"11년 세계에서 최장 운영운인 거래소, BTC/ETH 선물 계약 거래의 첫 번째 선택, 가장 낮은 거래 비용",
       推薦原因:"장점",
       交易體驗佳:"주문 즉시 거래 체결",
       最佳深度:"풍부한 거래량, 슬리피지 없음, 펀딩 비용 없음, 낮은 거래 수수료",
       歷史悠久的平臺:"무사고 110년, 믿을 수 있는 거래소",
       提供模擬交易:"초보자가 빠르게 적응할 수 있도록 모의거래 제공",
       劣勢:"단점：",
       僅提供市值前20的主流幣種的合約交易:"시장 가치 기준 상위 20개 주류 암호화폐의 선물 거래만 제공합니다. 기타 알트코인거래는 지원되지 않습니다.",
       立即下載:"지금 다운로드",
       下載:"https://trade.btcc.com/ko-KR/register?agentCode=21O1RB&utm_source=kol",
        Binance注冊:"https://accounts.binance.com/zh-CN/register?ref=PPMUM32W",
        Gate注冊:"https://www.gate.ac/signup/56341",
       Max:"Binance",
       幣種最全:"상장된 코인이 가장 많음，현물 거래의 첫 번째 선택",
       台灣現貨平臺:"대만 현물 거래소.  ",
       支持台币出入金:"대만달러 입출금 가능",
       注冊:"다운로드",
       max优势:"대만에서 라이센스를 받은 공식 거래소, 대만달러 입출금 가능",
       Coinlist:"Gate",
       打新首选平台:"새로운 거래소",
       小幣合約交易首選:"알트코인 선물 거래의 첫 번째 선택,Start up프로젝트 참여 가능"

   },
   partner:{
    夥伴招募:"파트너 모집",
    加密怪獸致力於:"크립토 몬스터는 일반 투자자들이 암호화폐 시장에서 수익성을 높일 수 있도록 전문적이고 객관적인 시장 분석과 산업 연구를 제공하기 위해 최선을 다하고 있습니다. 대부분의 팀원은 연구원, 분석가, 미디어, 프로젝트 전문가, 제품 관리자, 개발자 및 수년간 암호화폐 업계에 종사해온 전문투자자입니다. 2021년 DAO의 형태로 '크립토 몬스터'가탄생되었으며, 현재 중국, 대만, 한국, 호주 및 기타 시장으로 확장되었습니다.",
    内容提供者:"콘텐츠 매니저",
    如果你對加密貨幣新產業: "새로운 암호 화폐 산업에 대한 특별한 통찰력이 있거나 암호 화폐 거래의 고수 트레이더이시라면 가입을 환영합니다.",
    首席陪聊官: "커뮤니티 매니저",
    如果你有很長的時間在社交媒體上: "소셜 미디어 관리 경험이 있고 암호 화폐에 대한 강한 열정이 있으며 아이디어를 공유하고 다른 사람들과 상호 작용하는 것을 선호하신다면 환영합니다. ",
    外交部長: "소셜 트레이더",
    如果你活躍在加密貨幣行業: "암호 화폐 업계에서 활발히 활동하고 있으며 업계의 많은 사람들을 알고, 더 많은 아이디어와 의견 교환을 하길 원하신다면 환영합니다.",
    社群版主: "크립토 인플루언서",
    如果你是加密貨幣行業的版主: "암호화폐 업계의 어드바이저이며 다른 사람들과 의견을 공유할 수 있는 소셜 미디어 채널을 보유하고 있다면 환영합니다.",
    推廣流量: "트래픽 프로모션",
    優質内容: "프리미엄 콘텐츠",
    資金支持: "자금 투자",
    收益分紅: "수익 배당",
    福利優惠: "특혜",
    專屬NFT頭像: "NFT 전용 아바타",
    只要你擁有其中一項:"위의 항목 중 하나에 관심이 있다면 ",
    點擊聯係加入我們:"주저하지 말고 지금 가입해보세요",
    聯係:"가입 하기",
   },
    
}
