import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Ruoter from './ruoter';
// import reportWebVitals from './reportWebVitals';
import "./lang/i18n.js";
import "swiper/css/bundle";

ReactDOM.render(
  <React.StrictMode>
    <Ruoter />
  </React.StrictMode>,
  document.getElementById('root')
);

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
