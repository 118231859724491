import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter ,HashRouter} from "react-router-dom";
import LoadingSpin from "react-loading-spin";
const Index = lazy(() => import('./pages/App.js'))
const Recruit = lazy(() => import('./pages/recruit'))
const Quotes = lazy(() => import('./pages/quotes'))
const Discount = lazy(() => import('./pages/discount'))
const Onlinetrader = lazy(() => import('./pages/onlinetrader'))
const Onlineliveclass = lazy(() => import('./pages/onlineliveclass'))
const Onlinelive = lazy(() => import('./pages/onlinelive'))

export default () => {
  return (
    <BrowserRouter basename="monster-crypto">
      <Switch>
      <Suspense fallback={<></>}>
          <Route exact path="/:lang(en-GB|ja-JP|zh-TW|ko-KR|zh-CN)?/" component={Index} />
          <Route  path="/recruit/:lang(en-GB|ja-JP|zh-TW|ko-KR|zh-CN)?/" component={Recruit} />
          <Route  path="/quotes/:lang(en-GB|ja-JP|zh-TW|ko-KR)?/" component={Quotes} />
          <Route  path="/discount/:lang(en-GB|ja-JP|zh-TW|ko-KR)?/" component={Discount} />
          <Route  path="/onlinetrader/:lang(en-GB|ja-JP|zh-TW|ko-KR)?/" component={Onlinetrader} />
          <Route  path="/onlineliveclass/:lang(en-GB|ja-JP|zh-TW|ko-KR)?/" component={Onlineliveclass} />
          <Route  path="/onlinelive/:lang(en-GB|ja-JP|zh-TW|ko-KR)?/" component={Onlinelive} />
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
};
